import revive_payload_client_COskqraXhl from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@9.11.1_idb-keyva_54d6wc23h2nv6p75qb722ntx6m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kZYnhvECuI from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@9.11.1_idb-keyva_54d6wc23h2nv6p75qb722ntx6m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TK1KAtaFXt from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@9.11.1_idb-keyva_54d6wc23h2nv6p75qb722ntx6m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_psiVG7luyX from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@9.11.1_idb-keyva_54d6wc23h2nv6p75qb722ntx6m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_7oRj63rFHu from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@9.11.1_idb-keyva_54d6wc23h2nv6p75qb722ntx6m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_QDQp8pilxy from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt-snackbar@1.0.4_rollup@4.14.3_vue@3.4.22_typescript@5.6.2_/node_modules/nuxt-snackbar/dist/runtime/plugin.mjs";
import plugin_vue3_XThybg6COg from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.14.3_typescript@5.6.2_vue@3.4.22_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/workflows/console-ui/apps/content/.nuxt/components.plugin.mjs";
import prefetch_client_4hcbR7TbRf from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@9.11.1_idb-keyva_54d6wc23h2nv6p75qb722ntx6m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/builds/rialtic/workflows/console-ui/apps/content/.nuxt/unocss.mjs";
import pendo_hkesixFq4o from "/builds/rialtic/workflows/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/workflows/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import formkitPlugin_pZqjah0RUG from "/builds/rialtic/workflows/console-ui/apps/content/.nuxt/formkitPlugin.mjs";
import view_transitions_client_r57ikg7wUv from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@9.11.1_idb-keyva_54d6wc23h2nv6p75qb722ntx6m/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_XpHtKmDtse from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@9.11.1_idb-keyva_54d6wc23h2nv6p75qb722ntx6m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_PXQeuxRKoc from "/builds/rialtic/workflows/console-ui/packages/ui/src/runtime/plugin.ts";
import plugin_fpJmWg6MTM from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.4.11_rollup@4.14.3_typescript@5.6.2_vue_ouiqvypmq26st3usxshme5csrm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
export default [
  revive_payload_client_COskqraXhl,
  unhead_kZYnhvECuI,
  router_TK1KAtaFXt,
  payload_client_psiVG7luyX,
  check_outdated_build_client_7oRj63rFHu,
  plugin_QDQp8pilxy,
  plugin_vue3_XThybg6COg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4hcbR7TbRf,
  unocss_MzCDxu9LMj,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  formkitPlugin_pZqjah0RUG,
  view_transitions_client_r57ikg7wUv,
  chunk_reload_client_XpHtKmDtse,
  plugin_PXQeuxRKoc,
  plugin_fpJmWg6MTM
]