import { defu } from 'defu'
import type { FetchOptions } from 'ofetch'
import {
  makeTracingHeaders,
  useFetch,
  useNuxtApp,
} from '#imports'

const createDefaultOptions = (
  opts: FetchOptions & { [key: string]: string } = {},
) =>
  defu(opts, {
    async onRequest({ options }) {
      const { $auth0 } = useNuxtApp()
      const { getAccessTokenSilently } = $auth0()

      const token = await getAccessTokenSilently()

      

      const headers = {
        Authorization: `Bearer ${token}`,
        'auth-provider': 'auth0',
        ...makeTracingHeaders(),
      }

      options.headers = defu(headers, options.headers)
    },
  })

export const $apiFetcher = () => $fetch.create(createDefaultOptions())

type UseFetch = typeof useFetch

export const useApiFetch: UseFetch = (url, options = {}) =>
  useFetch(url, defu(options, createDefaultOptions()))
